/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"

function SEO({description, lang, meta, title, pageImage,path}) {
    const {site} = useStaticQuery(
        graphql`
       query {
         site {
           siteMetadata {
             defaultTitle
             titleTemplate
             description
             url
             image
             twitterUsername
           }
         }
       }
     `
    )

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        url,
        image,
        twitterUsername,
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        metaDescription: description || defaultDescription,
        ogImage: pageImage ? new URL(pageImage, url) || image : image,
        pageUrl: path ? new URL(path, url) : false,
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            description={description}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: seo.metaDescription,
                },
                {
                    property: `og:title`,
                    content: seo.title,
                },
                {
                    property: `og:description`,
                    content: seo.metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: seo.ogImage,
                },
                {
                    property: `og:url`,
                    content: seo.pageUrl,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: twitterUsername || ``,
                },
                {
                    name: `twitter:title`,
                    content: seo.title,
                },
                {
                    name: `twitter:description`,
                    content: seo.metaDescription,
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string
}

export default SEO
 