import React from 'react'
import styled from 'styled-components'
import {Link} from "gatsby";

function Footer() {
    return (
        <StyledWrapper>
            <div className="footer container-fluid">
                <div className="row footer__main">
                    <div className="col-lg-4 col-12">
                        <div className="footer__details">
                            <p>contact@1stmain.co</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-12 justify-content-center d-flex">
                        <div className="footer__team">
                            <Link to="/team">THE TEAM</Link>
                            <Link to="/contact">CONTACT</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 justify-content-center justify-content-lg-end d-flex">
                        <div className="footer__copyright">
                            <p>© 2021 KKNT First Main Private Limited</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>

    )
}

export default Footer

const StyledWrapper = styled.div`
  .footer {
    border-left: none;
    background: rgba(244, 242, 239, 1);
  }

  .footer__main {
    padding: 40px 54px 10px;
  }

  .footer__address {
    padding-inline-start: 0px;
  }

  .footer__copyright {
    display: flex;
    justify-content: center;
  }

  .footer__copyright > p {
    font-size: 10px;
    margin-bottom: 0;
  }

  .footer__team > a {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.05em;
    margin: 10px 10px;
    padding: 0;
  }

  .footer__details, .footer__address, .footer__team, .footer__copyright {
    text-align: center;
  }

  .footer__team {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
  }

  .footer__details {
    padding-bottom: 20px;
  }

    @media (min-width: 992px) {
    .footer {
      height: 100%;
      transition: 750ms;
    }

    .footer__copyright > p {
      font-size: 14px;
    }

      .footer__team > a {
        font-size: 12px;
        line-height: 24px;
        margin: 0 10px;
      }

    .footer__details, .footer__address, .footer__team, .footer__copyright {
      text-align: left;
    }

    .footer__main {
      padding: 50px 54px 0px;
    }

    .footer__team {
      padding-bottom: 30px;
      display: flex;
      flex-direction: row;
    }


    .footer__details {
      padding-bottom: 0;
    }

  }
`