import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {slide as Menu1} from 'react-burger-menu'
import {Link} from 'gatsby'
import firstMainLogo from '../images/firstMainLogo.svg';
import Fade from 'react-reveal/Fade';

const Menu = () => {

    const [value, setValue] = useState(false)

    useEffect(() => {
        setValue(true)
    }, [Menu1])

    return (
        <StyledWrapper>
            <div id="outer-container">
                {/* <Menu1 pageWrapId={"page-wrap"} outerContainerId={"outer-container"} /> */}
                <main id="page-wrap">
                    <Menu1 className="menu" pageWrapId="page-wrap">
                        <Fade top when={value}>
                            <div className="company__logo" onClick={(e) => {
                                e.preventDefault();
                                window.location.href = '/';
                            }}>
                                <img src={firstMainLogo} alt=""/>
                            </div>
                        </Fade>
                        <Fade cascade left>
                            <div className="menu-group">
                                <Link className="menu-item" to="/work" activeClassName={`active`}>Work</Link><br/>
                                <Link className="menu-item" to="/about" activeClassName={`active`}>About</Link><br/>
                                <Link className="menu-item" to="/career" activeClassName={`active`}>Careers</Link><br/>
                                <Link className="menu-item" to="/contact" activeClassName={`active`}>Contact</Link><br/>
                                <Link className="menu-item" to="/blog" activeClassName={`active`}>Blog</Link>
                            </div>
                        </Fade>
                        <div className="contact__details">
                            <h5>contact@1stmain.co</h5>
                        </div>
                        <div className="menu__media">
                            <a href="https://medium.com/crosswalk" target="_blank" rel="noreferrer">MEDIUM</a>|
                            <a href="https://www.instagram.com/1st.main/" target="_blank"
                               rel="noreferrer">INSTAGRAM</a>|
                            <a href="https://www.linkedin.com/company/1stmain/" target="_blank"
                               rel="noreferrer">LINKEDIN</a>
                        </div>
                    </Menu1>
                </main>
            </div>
        </StyledWrapper>
    );
}
export default Menu;


const StyledWrapper = styled.div`
  .active {
    color: #C15F6D !important;
  }
  

  
  .bm-burger-button {
    position: fixed;
    left: 7px;
    height: 20px;
    width: 25px;
    top: 25px;
  }

  .bm-burger-bars {
    background: black !important;
  }

  .bm-burger-bars {
    background: #373a47;
  }

  .bm-burger-bars-hover {
    background: #a90000;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: black !important;
    height: 20px !important;
  }

  .company__logo {
    margin-top: -44px;
  }

  .company__logo > img {
    height: 46px;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
  }

  .bm-menu-wrap {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    transition: all 0.8s ease 0s !important;
  }

  .bm-menu {
    background: #C2C9D1;
    padding: 50px 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .bm-overlay {
    background: #C2C9D1;
  }

  .contact__details {
    padding: 60px 0px 10px;
    color: black;
    text-align: center;
  }

  .menu__media {
    font-size: 12px;
    padding-bottom: 50px;
    width: 100%;
    text-align: center;
  }

  .menu__media > a {
    margin: 0px 5px !important;
  }

  .menu-group {
    text-align: center;
    margin: auto 0;
  }


  .menu-item, .menu-item:visited {
    display: inline-block !important;
    font-size: 36px;
    font-weight: 400;
    line-height: 60px;
    padding-bottom: 0px;
    outline: none;
    margin-top: 0px;
    position: relative;
    color: black;
    text-decoration: none;
    text-transform: none;
  }

  .menu-item:hover {
    color: #C15F6D !important;
  }

  a.menu-item::before {
    width: 100% !important;
    height: 5px !important;
    bottom: 10px !important;
  }

  .menu__media > a {
    color: black;
    text-decoration: none;
    font-size: 12px;
    font-weight: 300;
  }

  .contact {
    font-size: 14px;
  }

  .email {
    font-size: 14px;
  }

  .contact__details {
    padding: 60px 0px 0px;
    outline: none;
  }


  @media (min-width: 768px) {
    .menu-item {
      font-size: 62px;
      line-height: 84px;
    }

    .menu__media {
      padding-bottom: 30px;
    }
    .company__logo > img {
      height: 60px;
      margin-bottom: 20px;
    }

    .bm-burger-button {
      width: 30px;
      height: 20px;
      left: 15px;
      top: 36px;
    }
  }

  @media (min-width: 992px) {

    // adjust for left nav no desktop
    #page-wrap > div:first-child {
      margin-left: -60px;
    }
    
    .menu__media > a {
      position: relative;
    }

    .menu__media > a:hover {
      color: black !important;
    }

    .menu__media > a::before {
      content: "" !important;
      position: absolute !important;
      width: 100% !important;
      height: 2px !important;
      bottom: -4px !important;
      left: 0 !important;
      background-color: black !important;
      visibility: hidden !important;
      transform: scaleX(0) !important;
      transition: all 0.3s ease-in-out 0s !important;
    }

    .menu__media > a:hover::before {
      visibility: visible !important;
      transform: scaleX(1) !important;
    }
  }

`